
    import { Options, Vue } from "vue-class-component"
    // eslint-disable-next-line
    import { Container, RecordReceived, ShareRecordReceived, _CloudKit } from "../cloudkit.d"
    import ConferenceCard from "../components/ConferenceCard.vue"

    @Options({
        props: {
            meetings: Array,
            container: Object
        },
        components: {
            ConferenceCard
        }
    })
    export default class Gallery extends Vue {
        meetings?: RecordReceived[] | ShareRecordReceived[]
        container?: Container

        mounted() {}
    }
