
    import { Options, Vue } from "vue-class-component"
    import { Container, RecordReceived, RecordSaveOptions, ShareRecordReceived } from "../cloudkit"
    import {
        BrowserConference,
        ConferenceType,
        GenericConference,
        getSimpleMeeting,
        openMeeting,
        SkypeConference,
        typeToEntityName,
        typeToString,
        ZoomConference
    } from "../scripts/meetings"

    @Options({
        props: {
            meeting: Object,
            container: Object
        }
    })
    export default class ConferenceCard extends Vue {
        meeting?: RecordReceived | ShareRecordReceived
        container?: Container

        editing: Boolean = false

        typeOptions: ConferenceType[] = [ConferenceType.browser, ConferenceType.skype, ConferenceType.zoom]

        editName: String = ""
        editType = ConferenceType.unknown
        editpassword: String = ""
        editzoomConfID: String = ""
        editzoomSubdomain: String = ""
        editskypeCode: String = ""
        editbrowserURL: String = "https://"

        get simple(): GenericConference {
            if (this.meeting == undefined) return {} as GenericConference
            return getSimpleMeeting(this.meeting!) as GenericConference
        }

        openConference(meeting: GenericConference) {
            openMeeting(meeting)
        }

        confTypeToString(type: ConferenceType): String {
            return typeToString(type)
        }

        startEdit() {
            this.editing = true
            const simple = this.simple as GenericConference
            this.editName = simple.name
            this.editType = simple.type
            switch (this.editType) {
                case ConferenceType.skype: {
                    this.editskypeCode = (simple as SkypeConference).code
                    break
                }
                case ConferenceType.browser: {
                    const br = simple as BrowserConference
                    this.editpassword = br.password
                    this.editbrowserURL = br.url
                    break
                }
                case ConferenceType.zoom: {
                    const zoom = simple as ZoomConference
                    this.editzoomConfID = zoom.conferenceID
                    this.editzoomSubdomain = zoom.zoomServerSubdomain
                    this.editpassword = zoom.password
                    break
                }
            }
            console.log(this.meeting)
        }

        cancel() {
            this.editing = false
        }

        genFieldsData(type: ConferenceType) {
            let data = {}
            switch (type) {
                case ConferenceType.zoom: {
                    data = {
                        CD_entityName: { value: typeToEntityName(type), type: "STRING" },
                        CD_name: { value: this.editName, type: "STRING" },
                        CD_password: { value: this.editpassword, type: "STRING" },
                        CD_conferenceID: { value: this.editzoomConfID, type: "STRING" },
                        CD_zoomServerSubdomain: { value: this.editzoomSubdomain, type: "STRING" }
                    }
                    break
                }
                case ConferenceType.browser: {
                    data = {
                        CD_entityName: { value: typeToEntityName(type), type: "STRING" },
                        CD_name: { value: this.editName, type: "STRING" },
                        CD_password: { value: this.editpassword, type: "STRING" },
                        CD_url: { value: this.editbrowserURL, type: "STRING" }
                    }
                    break
                }
                case ConferenceType.skype: {
                    data = {
                        CD_entityName: { value: typeToEntityName(type), type: "STRING" },
                        CD_name: { value: this.editName, type: "STRING" },
                        CD_code: { value: this.editskypeCode, type: "STRING" }
                    }
                }
            }
            return data
        }

        async save() {
            let data = this.genFieldsData((this.simple as GenericConference).type)
            if (this.meeting != undefined) {
                // type did not change
                if ((this.simple as GenericConference).type == this.editType) {
                    this.meeting!.fields = data as any
                    const options = {
                        operationType: "forceUpdate",
                        zoneID: "com.apple.coredata.cloudkit.zone"
                    } as RecordSaveOptions
                    const retDat = await this.container!.privateCloudDatabase.saveRecords([this.meeting!], options)
                    console.log(retDat)
                    //type changed
                } else {
                    console.warn("not implemented")
                    /* await this.delete()
                    let record = {
                        recordType: "CD_GenericConference",
                        fields: data
                    } as any
                    console.log(record)

                    const options = {
                        operationType: "create",
                        zoneID: "com.apple.coredata.cloudkit.zone"
                    } as RecordSaveOptions
                    const retDat = await this.container!.privateCloudDatabase.newRecordsBatch().create(record).commit()
                    console.log(retDat) */
                }
                // is new entry
            } else {
                console.warn("not implemented")
            }
            /* data = {
                CD_entityName: { value: typeToEntityName(this.editType), type: "STRING" },
                CD_name: { value: this.editName, type: "STRING" },
                CD_password: { value: this.editpassword, type: "STRING" },
                CD_conferenceID: { value: this.editzoomConfID, type: "STRING" },
                CD_zoomServerSubdomain: { value: this.editzoomSubdomain, type: "STRING" },
                CD_code: { value: this.editskypeCode, type: "STRING" },
                CD_url: { value: this.editbrowserURL, type: "STRING" }
            } */

            this.editing = false
            this.triggerUpdate()
        }

        triggerUpdate() {
            this.$forceUpdate()
            this.$emit("reloadMeetings")
        }

        async userDelete() {
            if (
                confirm(
                    "Are you sure you want to delete this entry named " + (this.simple as GenericConference).name + " ?"
                )
            )
                await this.delete()
            this.triggerUpdate()
        }

        async delete() {
            const deleteRet = await this.container!.privateCloudDatabase.deleteRecords([this.meeting!], {
                zoneID: "com.apple.coredata.cloudkit.zone"
            })
            console.log(deleteRet)
            /* if (this.meeting != undefined && this.container != undefined)
                this.container!.privateCloudDatabase.deleteRecords([this.meeting!]) */
        }
    }
