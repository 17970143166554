import { RecordReceived, ShareRecordReceived } from "@/cloudkit"
import { copyToClipboard } from "./helper"

export enum ConferenceType {
    unknown,
    browser,
    skype,
    zoom
}

export function typeToString(type: ConferenceType): string {
    switch (type) {
        case ConferenceType.browser:
            return "Browser"
        case ConferenceType.zoom:
            return "Zoom"
        case ConferenceType.skype:
            return "Skype"
        case ConferenceType.unknown:
            return "unknown"
    }
    return ""
}

export function typeToEntityName(type: ConferenceType): string {
    switch (type) {
        case ConferenceType.browser:
            return "BrowserConference"
        case ConferenceType.zoom:
            return "ZoomConference"
        case ConferenceType.skype:
            return "SkypeConference"
        case ConferenceType.unknown:
            return "unknown"
    }
}

export function typeToRecordType(type: ConferenceType): string {
    switch (type) {
        case ConferenceType.browser:
            return "CD_BrowserConference"
        case ConferenceType.zoom:
            return "CD_ZoomConference"
        case ConferenceType.skype:
            return "CD_SkypeConference"
        case ConferenceType.unknown:
            return "CD_Generic"
    }
}

export interface GenericConference {
    name: String
    type: ConferenceType
}

export interface BrowserConference extends GenericConference {
    password: String
    url: String
}

export interface SkypeConference extends GenericConference {
    code: String
}

export interface ZoomConference extends GenericConference {
    conferenceID: String
    password: String
    zoomServerSubdomain: String
}

export function getSimpleMeeting(
    raw: RecordReceived | ShareRecordReceived
): GenericConference | RecordReceived | ShareRecordReceived {
    const fields = raw.fields
    switch (fields["CD_entityName"]?.value?.toString().toLowerCase() ?? "") {
        case "zoomconference":
            return {
                conferenceID: fields["CD_conferenceID"].value as String,
                name: fields["CD_name"].value as String,
                password: fields["CD_password"].value as String,
                zoomServerSubdomain: fields["CD_zoomServerSubdomain"].value as String,
                type: ConferenceType.zoom
            } as ZoomConference
        case "skypeconference":
            return {
                code: fields["CD_code"].value as String,
                name: fields["CD_name"].value as String,
                type: ConferenceType.skype
            } as SkypeConference
        case "browserconference":
            return {
                url: fields["CD_url"].value as String,
                password: fields["CD_password"].value as String,
                name: fields["CD_name"].value as String,
                type: ConferenceType.browser
            } as BrowserConference
    }
    return raw
}

export function openMeeting(meeting: GenericConference) {
    switch (meeting.type) {
        case ConferenceType.zoom: {
            const zoomMeeting = meeting as ZoomConference
            joinZoomMeeting(zoomMeeting.conferenceID, zoomMeeting.password, zoomMeeting.zoomServerSubdomain)
            break
        }
        case ConferenceType.browser: {
            copyToClipboard((meeting as BrowserConference).password)
            window.open((meeting as BrowserConference).url as string, "_blank")!.focus()
            break
        }
        case ConferenceType.skype: {
            window
                .open(
                    ("https://join.skype.com/" + (meeting as SkypeConference).code.replaceAll(" ", "")) as string,
                    "_blank"
                )!
                .focus()
            break
        }
        case ConferenceType.unknown: {
            console.warn("unknown conference type")
            break
        }
    }
}

function joinZoomMeeting(meetingID: String, pwd: String, subdomain: String = "") {
    copyToClipboard(pwd)
    let prefix = "zoommtg"
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) prefix = "zoomus"
    const loc =
        prefix +
        "://" +
        (subdomain != "" ? subdomain + "." : "") +
        "zoom.us/join?confno=" +
        meetingID.replaceAll(" ", "") +
        "&pwd=" +
        pwd
    console.log(loc)
    // @ts-ignore
    window.location = loc
    //zoommtg://zoom.us/join?confno=86704127353?pwd=K2MxMjliOEhQTmhSZ2Jpd2J6Nmx6QT09
}
